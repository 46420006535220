import React, { useState } from 'react'
import PropTypes from 'prop-types'

import FormHint from '../common/FormHint'
import UrlField from '../common/UrlField'
import CallToActionAdvancedOptions from './CallToActionAdvancedOptions'

const CallToActionButton = ({ cta, user, onChangeCta, onValidate }) => {
  const [showAdvanced, setShowAdvanced] = useState(false)

  return (
    <React.Fragment>
      <div className="mb-4">
        <label className="custom-form-label">CTA Name</label>
        <FormHint text="Give your CTA a name for convenience" />
        <div>
          <input
            type="text"
            name="call_to_action[name]"
            value={cta.name || ''}
            onChange={e => onChangeCta({ ...cta, name: e.target.value })}
            className="custom-form-text"
            placeholder="e.g My awesome CTA"
            required="required"
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="custom-form-label">Anchor Text</label>
        <FormHint text="Text the user will click to get to your site" />
        <div>
          <input
            type="text"
            name="call_to_action[payload][text]"
            value={cta.payload.text}
            onChange={e => onChangeCta({ ...cta, payload: { ...cta.payload, text: e.target.value } })}
            className="custom-form-text"
            placeholder="e.g This is my new Call-to-Action"
            required="required"
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="custom-form-label">Button Text</label>
        <FormHint text="Text the user will click to get to your site" />
        <div>
          <input
            type="text"
            name="call_to_action[payload][btn_text]"
            value={cta.payload.btn_text}
            onChange={e => onChangeCta({ ...cta, payload: { ...cta.payload, btn_text: e.target.value } })}
            className="custom-form-text"
            placeholder="e.g. Click to learn more"
            required="required"
          />
        </div>
      </div>
      <div className="mb-4">
        <UrlField
          label="Destination URL"
          name="call_to_action[payload][url]"
          value={cta.payload.url || ''}
          onChange={e => onChangeCta({ ...cta, payload: { ...cta.payload, url: e.target.value } })}
          hint="URL the user will be redirected to after clicking the button"
          onValidate={onValidate}
        />
      </div>
      <div className="mb-4 text-center font-medium">
        <a onClick={() => setShowAdvanced(!showAdvanced)} className="btn btn-sm btn-default text-xs">
          {showAdvanced ? 'Close advanced options' : 'Open advanced options'}
        </a>
      </div>
      <CallToActionAdvancedOptions cta={cta} user={user} onChange={onChangeCta} show={showAdvanced} />
    </React.Fragment>
  )
}

CallToActionButton.propTypes = {
  cta: PropTypes.object,
  user: PropTypes.object,
  onChangeCta: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired
}

export default CallToActionButton
