import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import FormHint from './FormHint'

const ValidationIcon = ({ loading, status }) => {
  if (loading) {
    return <i className="fa fa-spinner fa-spin absolute right-0" style={{ top: '10px', right: '10px' }} />
  }

  return (
    <React.Fragment>
      <i
        className={`fa-check absolute right-0 text-green-500 ${status === 'valid' ? 'fa' : 'hidden'}`}
        style={{ top: '10px', right: '10px' }}
      />
      <div
        aria-label="Invalid URL"
        className={`${status === 'invalid' ? 'inline-block' : 'hidden'}`}
        data-balloon-pos="up"
      >
        <i className="fa fa-exclamation-triangle absolute text-red-600" style={{ top: '10px', right: '10px' }} />
      </div>
    </React.Fragment>
  )
}

ValidationIcon.propTypes = {
  loading: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired
}

// eslint-disable-next-line react/display-name
const UrlField = ({ label, name, placeholder, hint, onChange, value, onValidate }, ref) => {
  const [loading, setLoading] = useState(false)
  const [validationStatus, setValidationStatus] = useState('ready')
  const [validationError, setValidationError] = useState(null)
  const validationUrl = name.includes('link[url]')
    ? `/links/validate?url=${value}`
    : `/call_to_actions/validate?url=${value}`
  const timer = useRef(null)

  useEffect(() => {
    setValidationError(null)

    if (!value) {
      setValidationStatus('ready')
      return
    }

    setLoading(true)
    onValidate({ loading: true })

    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }

    timer.current = setTimeout(() => {
      fetch(validationUrl)
        .then(res => res.json())
        .then(res => {
          if (res.status === 'ok') {
            setValidationError(null)
            setValidationStatus('valid')
            onValidate({ ...res, link: value, valid: true })
          } else {
            setValidationError(res.message)
            setValidationStatus('invalid')
            onValidate({ error: true })
          }
        })
        .finally(() => setLoading(false))
    }, 500)
  }, [value])

  return (
    <React.Fragment>
      <label className="custom-form-label">{label}</label>
      {hint && <FormHint text={hint} />}
      <div className="inline-flex w-full relative">
        <input
          type="text"
          name={name}
          value={value}
          className="custom-form-text custom-form-text--with-status focus"
          placeholder={placeholder}
          required="required"
          onChange={onChange}
        />
        <ValidationIcon loading={loading} status={validationStatus} />
      </div>
      <div
        className={`w-full inline-block mt-4 text-center ${
          validationStatus === 'valid' || validationStatus === 'ready' ? 'hidden' : 'inline-block'
        }`}
      >
        <div className="bg-red-300 px-1 py-1 rounded">
          <i className="fa fa-thumbs-down text-red-800"></i>
          <small className="ml-2 text-green-900 font-semibold">{validationError || 'Invalid URL'}</small>
          <small className="ml-2 text-green-900 font-semibold">
            <div
              aria-label="URLs should be set in full structure, including http/https. e.g https://my-website.com"
              className="inline-block"
              data-balloon-length="large"
              data-balloon-pos="up"
            >
              <i className="far fa-question-circle"></i>
            </div>
          </small>
        </div>
      </div>
      <div
        className={`w-full inline-block mt-4 text-center ${validationStatus === 'valid' ? 'inline-block' : 'hidden'}`}
      >
        <div className="bg-green-300 px-1 py-1 rounded">
          <i className="fa fa-thumbs-up text-green-800"></i>
          <small className="ml-2 text-green-900 font-semibold">Link validated</small>
        </div>
      </div>
    </React.Fragment>
  )
}

UrlField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  value: PropTypes.string
}

UrlField.defaultProps = {
  placeholder: 'e.g. https://my-website.com',
  onValidate: _ => null,
  value: ''
}

export default UrlField
