import React from 'react'
import PropTypes from 'prop-types'

import WebsiteSkeleton from '../common/WebsiteSkeleton'

const LinkPreview = ({ linkData }) => {
  if (!linkData || !linkData.link) {
    return (
      <div
        className="md:block md:w-5/6 bg-white rounded shadow-md relative mb-20 pb-10"
        style={{ height: 'fit-content' }}
      >
        <WebsiteSkeleton animate={linkData?.loading} />
      </div>
    )
  }

  const wrapperClasses = ['md:block w-full bg-white rounded shadow-md pb-10 relative']
  const iframeClasses = ['opacity-100']

  if (linkData.youtube) {
    iframeClasses.push('w-full h-auto')
  } else if (linkData.forbes) {
    wrapperClasses.push('flex md:flex justify-center md:justify-center')
    iframeClasses.push('w-480')
  } else {
    iframeClasses.push('w-full min-h-full')
  }

  return (
    <div className={wrapperClasses.join(' ')}>
      <iframe
        sandbox="allow-scripts allow-forms allow-same-origin"
        src={linkData.amp_url || linkData.link}
        className={iframeClasses.join(' ')}
        style={{ height: '-webkit-fill-available' }}
        scrolling="no"
      />
    </div>
  )
}

LinkPreview.propTypes = {
  linkData: PropTypes.object
}

export default LinkPreview
