import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import CallToActionPreview from './CallToActionPreview'
import SelectField from '../common/SelectField'
import CallToActionButton from './CallToActionButton'
import CallToActionWhatsapp from './CallToActionWhatsapp'
import CallToActionPhone from './CallToActionPhone'
import CallToActionForm from './CallToActionForm'

const csrfToken = document.querySelector('meta[name="csrf-token"]').content

const CTA_TYPES = [
  { label: 'Button', value: 'button', component: CallToActionButton },
  { label: 'Whatsapp', value: 'whatsapp', component: CallToActionWhatsapp },
  { label: 'Phone', value: 'phone', component: CallToActionPhone },
  { label: 'Form (Lead Generation)', value: 'form', component: CallToActionForm },
  { label: 'Below are coming soon', disabled: true },
  { label: '----------------------', disabled: true },
  { label: 'Pixels', disabled: true },
  { label: 'Text Link', disabled: true },
  { label: 'Modal', disabled: true },
  { label: 'Banner', disabled: true },
  { label: 'Social links', disabled: true }
]

const getCtaComponent = ctaType => {
  if (ctaType) {
    return CTA_TYPES.find(c => c.value === ctaType).component
  }

  return CallToActionButton
}

const CallToAction = ({ callToAction, user, campaigns, payloads }) => {
  const [cta, setCta] = useState(callToAction)
  const [ctaChanged, setCtaChanged] = useState(false)
  const [urlValidationData, setUrlValidationData] = useState({ valid: !!callToAction.id })

  const queryParams = new URLSearchParams(window.location.search)
  const step = queryParams.get('step')

  const formDetails = useMemo(() => {
    if (cta.id) {
      return {
        url: `/call_to_actions/${cta.id}`,
        method: 'patch'
      }
    }

    return {
      url: '/call_to_actions',
      method: 'post'
    }
  })

  const updateCtaType = e => {
    const ctaType = e.target.value

    if (ctaChanged) {
      setCta({ ...cta, cta_type: e.target.value })
    } else {
      setCta({ ...cta, cta_type: e.target.value, payload: payloads[ctaType] })
    }
  }

  const updateCta = cta => {
    setCtaChanged(true)
    setCta(cta)
  }

  const CtaComponent = getCtaComponent(cta.cta_type)

  return (
    <>
      {step && (
        <div className="flex justify-center">
          <div className="w-full md:max-w-4xl bg-indigo-200 mb-10 p-5 rounded-md">
            <h2 className="text-xl md:text-2xl font-bold">Let's create your first Call-to-Action! 👊</h2>
            <p className="text-lg mt-5">Your first step is to create a call to action.</p>
            <p className="text-lg">
              The call to action is the box overlay that will appear over the 3rd party link you want to share.
            </p>
            <p className="text-lg">Your users will click it in order to get to your destination website.</p>
            <p className="text-lg mt-5 font-extrabold">Notes:</p>
            <ul className="list-inside list-disc">
              <li className="text-lg">
                <span>Keep the span.font-extrabold CTA Type span as Button for this example</span>
              </li>
              <li className="text-lg">
                <span>The URL you type here is span.font-extrabold your span website</span>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="flex flex-row gap-10">
        <form className="min-w-full md:w-2/6 md:min-w-0" action={formDetails.url} method="post">
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <input type="hidden" name="_method" value={formDetails.method} />
          <input type="hidden" name="cta_uid" value={cta.uid || ''} />
          {step && <input type="hidden" name="step" value={step} />}

          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <div className="mb-4">
                <SelectField
                  label="CTA Type"
                  name="call_to_action[cta_type]"
                  require="true"
                  value={cta.cta_type || 'button'}
                  onChange={updateCtaType}
                  options={CTA_TYPES}
                  hint="Call-To-Action to be attached to links you share"
                />
              </div>
              <div className="bg-gray-200 p-5 rounded">
                <CtaComponent cta={cta} user={user} onChangeCta={updateCta} onValidate={setUrlValidationData} />
              </div>
              <div className="my-4">
                <SelectField
                  label="Campaign"
                  name="call_to_action[campaign_id]"
                  require="true"
                  value={cta.campaign_id || campaigns[0]?.value || ''}
                  onChange={e => setCta({ ...cta, campaign_id: e.target.value })}
                  options={campaigns}
                />
              </div>
              <div className="mt-5 text-center">
                <input
                  className={`btn btn-blue ${urlValidationData.valid ? '' : 'disabled'}`}
                  type="submit"
                  value={cta.id ? 'Update CTA' : 'Create CTA'}
                  disabled={!urlValidationData.valid}
                />
              </div>
            </div>
          </div>
        </form>
        <CallToActionPreview cta={cta} />
      </div>
    </>
  )
}

CallToAction.propTypes = {
  callToAction: PropTypes.object.isRequired,
  payloads: PropTypes.object.isRequired,
  campaigns: PropTypes.array,
  user: PropTypes.object
}

CallToAction.defaultProps = {
  campaigns: []
}

export default CallToAction
