import React from 'react'
import PropTypes from 'prop-types'

const FormHint = ({ text }) => (
  <small aria-label={text} className="inline-block ml-2" data-balloon-length="large" data-balloon-pos="up">
    <i className="far fa-question-circle text-gray-500"></i>
  </small>
)

FormHint.propTypes = {
  text: PropTypes.string
}

export default FormHint
