import React from 'react'
import PropTypes from 'prop-types'

const WebsiteSkeleton = ({ animate, bgColor = 'bg-gray' }) => (
  <div className={`${animate ? 'animate-pulse' : ''}`}>
    <div className={`h-10 ${bgColor}-400 flex items-center pl-2`}>
      <div className="bg-red-600 rounded-full mx-2 p-2"></div>
      <div className="bg-yellow-600 rounded-full mx-2 p-2"></div>
      <div className="bg-green-600 rounded-full mx-2 p-2"></div>
    </div>
    <div className={`h-24 ${bgColor}-400 m-5 flex items-center px-10 py-16`}>
      <div className={`${bgColor}-600 rounded-full p-10`}></div>
      <div className={`h-8 w-full ${bgColor}-300 ml-10 mr-10 rounded`}></div>
    </div>
    <div className="grid grid-cols-4 m-5 gap-3">
      <div className={`h-6 ${bgColor}-400 rounded`}></div>
      <div className={`h-6 ${bgColor}-400 rounded`}></div>
      <div className={`h-6 ${bgColor}-400 rounded`}></div>
      <div className={`h-6 ${bgColor}-400 rounded`}></div>
      <div className={`h-6 ${bgColor}-400 rounded`}></div>
      <div className={`h-6 ${bgColor}-400 rounded`}></div>
      <div className={`h-6 ${bgColor}-400 rounded`}></div>
      <div className={`h-6 ${bgColor}-400 rounded`}></div>
    </div>
    <div className="grid grid-cols-2">
      <div className="mx-5">
        <div className="flex justify-between gap-5">
          <div className={`w-64 h-6 ${bgColor}-200 rounded`}></div>
          <div className={`w-64 h-6 ${bgColor}-200 rounded`}></div>
        </div>
        <div className="flex justify-between gap-5 my-3">
          <div className={`w-64 h-6 ${bgColor}-300 rounded`}></div>
          <div className={`w-64 h-6 ${bgColor}-300 rounded`}></div>
        </div>
        <div className="flex justify-between gap-5 my-3">
          <div className={`w-64 h-6 ${bgColor}-400 rounded`}></div>
          <div className={`w-64 h-6 ${bgColor}-400 rounded`}></div>
        </div>
        <div className="flex justify-between gap-5 my-3">
          <div className={`w-64 h-6 ${bgColor}-500 rounded`}></div>
          <div className={`w-64 h-6 ${bgColor}-500 rounded`}></div>
        </div>
        <div className="flex justify-between gap-5 my-3">
          <div className={`w-64 h-6 ${bgColor}-600 rounded`}></div>
          <div className={`w-64 h-6 ${bgColor}-600 rounded`}></div>
        </div>
        <div className="flex justify-between gap-5 my-3">
          <div className={`w-64 h-6 ${bgColor}-700 rounded`}></div>
          <div className={`w-64 h-6 ${bgColor}-700 rounded`}></div>
        </div>
      </div>
      <div className="mx-5">
        <div className="flex justify-between gap-5">
          <div className={`w-64 h-48 ${bgColor}-400`}></div>
          <div className={`w-64 h-48 ${bgColor}-300`}></div>
        </div>
      </div>
    </div>
  </div>
)

WebsiteSkeleton.propTypes = {
  animate: PropTypes.bool,
  bgColor: PropTypes.string.isRequired
}

WebsiteSkeleton.defaultProps = {
  animate: false,
  bgColor: 'bg-gray'
}

export default WebsiteSkeleton
