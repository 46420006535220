import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import WebsiteSkeleton from '../../common/WebsiteSkeleton'

const WhatsAppPreview = ({ cta }) => {
  const onClickCta = useCallback(
    e => {
      e.preventDefault()
      return
    },
    [cta]
  )

  return (
    <>
      <WebsiteSkeleton /*bgColor="bg-red"*/ />

      <div
        onClick={onClickCta}
        className={`absolute max-w- md:max-w-sm md:m-5 py-6 px-5 md:rounded-lg font-semibold shadow-lg flex items-center justify-between cursor-pointer gap-2 ${cta.payload.position}`}
        style={{ backgroundColor: cta.payload.bg_color, color: cta.payload.text_color }}
      >
        <div className="ltr flex flex-row absolute top-0 right-0 pt-1 mr-5 text-xs text-gray-600 items-center">
          <span className="powered-by hover:text-gray-700" rel="noopener noreferrer nofollow" target="_blank">
            <span className="powered-by">Linkush</span>
          </span>
          <span>&nbsp;</span>
          <span className="powered-by hover:text-gray-700" rel="noopener noreferrer nofollow" target="_blank">
            <i className="fa fa-question-circle powered-by"></i>
          </span>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <span className="powered-by hover:text-gray-700">
            <i className="fa fa-times powered-by"></i>
          </span>
        </div>
        <div className="w-full flex" style={{ direction: cta.payload.direction }}>
          <div className="flex flex-col">
            <div className="flex items-center">
              <i className="fab fa-whatsapp fa-4x"></i>
              <span className="w-full mx-2">{cta.payload.text || 'Edit this text to whatever you want'}</span>
            </div>
            <div className="flex mt-5">
              <span
                className="h-full text-center bg-indigo-300 p-3 rounded-lg shadow-lg w-full self-center"
                style={{ backgroundColor: cta.payload.btn_bg_color, color: cta.payload.btn_text_color }}
              >
                {cta.payload.btn_text || 'Start chat now'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

WhatsAppPreview.propTypes = {
  cta: PropTypes.object.isRequired
}

export default WhatsAppPreview
