import React from 'react'
import PropTypes from 'prop-types'

import FormHint from './FormHint'

const SelectField = ({ label, name, required, value, onChange, options, hint }) => {
  return (
    <React.Fragment>
      {label && <label className="custom-form-label">{label}</label>}
      {hint && <FormHint text={hint} />}
      <div className="relative w-full">
        <select className="custom-form-text" name={name} require={required} value={value} onChange={onChange}>
          {options.map(option => (
            <option key={`option-${option.label}`} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
          </svg>
        </div>
      </div>
    </React.Fragment>
  )
}

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  hint: PropTypes.string
}

SelectField.defaultProps = {
  required: 'false',
  value: '',
  options: []
}

export default SelectField
