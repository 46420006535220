import React from 'react'
import PropTypes from 'prop-types'
import FormHint from '../common/FormHint'

const CheckBoxField = ({ name, label, checked, disabled, disabledHint, onChange, tooltip }) => {
  const onClickCheckbox = e => {
    if (disabled) {
      return
    }

    onChange(e)
  }

  return (
    <React.Fragment>
      <label className="custom-form-label">{label}</label>
      {tooltip && <FormHint text={tooltip} />}
      <div
        className="block flex justify-center"
        aria-label={disabled ? disabledHint : undefined}
        data-balloon-pos={disabled ? 'up' : undefined}
      >
        <button
          type="button"
          aria-pressed="false"
          className={`relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
            checked ? 'bg-indigo-600' : 'bg-gray-400'
          } ${disabled ? 'disabled' : ''}`}
          onClick={onClickCheckbox}
          value={checked}
        >
          <span
            className={`relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${
              checked ? 'translate-x-5' : 'translate-x-0'
            }`}
          >
            <span
              className={`absolute inset-0 h-full w-full flex items-center justify-center transition-opacity ${
                checked ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'
              }`}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
            <span
              className={`absolute inset-0 h-full w-full flex items-center justify-center transition-opacity ${
                checked ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'
              }`}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"></path>
              </svg>
            </span>
          </span>
        </button>
        <input
          type="checkbox"
          name={name}
          value={checked ? '1' : '0'}
          checked={checked}
          className="h-5 w-5 rounded-full hidden"
          readOnly
        />
      </div>
    </React.Fragment>
  )
}

CheckBoxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabledHint: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

CheckBoxField.defaultProps = {
  checked: false,
  disabled: false
}

export default CheckBoxField
