import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import UrlField from '../common/UrlField'
import SelectField from '../common/SelectField'
import LinkPreview from './LinkPreview'

import Select from 'react-select'

const csrfToken = document.querySelector('meta[name="csrf-token"]').content

const LinkForm = ({ link: formLink, callToActions, campaigns, jsSnippets, jsSnippetsSelected }) => {
  const [link, setLink] = useState(formLink)
  const [linkData, setLinkData] = useState({ valid: !!formLink.id, link: formLink.url })
  const [selectedOptions, setSelectedOptions] = useState()

  const handleSelect = data => {
    setSelectedOptions(data)
  }

  const queryParams = new URLSearchParams(window.location.search)
  const step = queryParams.get('step')

  const formDetails = useMemo(() => {
    if (link.id) {
      return {
        url: `/links/${link.id}`,
        method: 'patch'
      }
    }

    return {
      url: '/links',
      method: 'post'
    }
  })

  return (
    <>
      {step && (
        <div className="flex justify-center">
          <div className="w-full md:max-w-4xl bg-indigo-200 mb-10 p-5 rounded-md">
            <h2 className="text-xl md:text-2xl">Great Call-to-Action! 👏👏</h2>
            <h3 className="text-lg md:text-xl font-bold">Now let's attach it to an article or website.</h3>
            <p className="text-lg mt-5">
              This is usually a third-party article or website which does not belong to you.
            </p>
          </div>
        </div>
      )}
      <div className="flex gap-10 max-h-screen">
        <div className="max-w-none md:max-w-xs">
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" action={formDetails.url} method="post">
            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <input type="hidden" name="_method" value={formDetails.method} />
            <input type="hidden" name="link[amp_url]" value={linkData.amp_url || ''} />
            {step && <input type="hidden" name="link[step]" value={step} />}

            <div className="mb-4">
              <UrlField
                label="Destination URL"
                name="link[url]"
                value={link.url || ''}
                onChange={e => setLink({ ...link, url: e.target.value })}
                onValidate={setLinkData}
                hint="This is the website you want to share with your audience"
              />
            </div>
            <div className="mb-4">
              <SelectField
                label="Call to action"
                name="link[cta_uid]"
                require="true"
                value={link.cta_uid || ''}
                onChange={e => setLink({ ...link, cta_uid: e.target.value })}
                options={callToActions}
                hint="Choose Call-To-Action to attach to this link"
              />
              <small className="ml-3 mt-1 block">
                <a className="link" href="/call_to_actions/new">
                  or create new call to action
                </a>
              </small>
            </div>
            <div className="mb-4">
              <SelectField
                label="Campaign"
                name="link[campaign_id]"
                require="true"
                value={link.campaign_id || ''}
                onChange={e => setLink({ ...link, campaign_id: e.target.value })}
                options={campaigns}
                hint="Use different campaigns to organize your links"
              />
              <small className="ml-3 mt-1 block">
                <a className="link" href="/campaigns/new">
                  or create new campaign
                </a>
              </small>
            </div>
            <div className="mb-4">
              <label className="custom-form-label">Javascript Snippets</label>
              <small
                aria-label="Javascript Snippets allow you to add different pixels and fire them when the page is loaded"
                className="inline-block ml-2"
                data-balloon-length="large"
                data-balloon-pos="up"
              >
                <i className="far fa-question-circle text-gray-500"></i>
              </small>
              <Select
                name="link[js_snippet_uids][]"
                options={jsSnippets}
                placeholder="Search.."
                value={selectedOptions}
                onChange={handleSelect}
                isSearchable={true}
                defaultValue={jsSnippetsSelected}
                isMulti
              />
              <small className="ml-3 mt-1 block">
                <a className="link" href="/js_snippets/new">
                  or create new Javascript Snippet
                </a>
              </small>
            </div>
            <div className="mt-10 text-center">
              <input
                className={`btn btn-blue ${linkData.valid ? '' : 'disabled'}`}
                type="submit"
                value={link.id ? 'Update Link' : 'Create Link'}
                disabled={!linkData.valid}
              />
            </div>
          </form>
        </div>
        <LinkPreview linkData={linkData} />
      </div>
    </>
  )
}

LinkForm.propTypes = {
  link: PropTypes.object.isRequired,
  callToActions: PropTypes.array,
  campaigns: PropTypes.array,
  jsSnippets: PropTypes.array
}

LinkForm.defaultProps = {
  callToActions: [],
  campaigns: [],
  jsSnippets: []
}

export default LinkForm
