import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FormHint from '../common/FormHint'
import CallToActionAdvancedOptions from './CallToActionAdvancedOptions'

const CallToActionForm = ({ cta, onChangeCta, user, onValidate }) => {
  const [showAdvanced, setShowAdvanced] = useState(false)

  useEffect(() => {
    if (cta.name?.length > 0) {
      onValidate({ valid: true })
    }
  }, [cta])

  return (
    <React.Fragment>
      <div className="mb-4">
        <label className="custom-form-label">CTA Name</label>
        <FormHint text="Give your CTA a name for convenience" />
        <div>
          <input
            type="text"
            name="call_to_action[name]"
            value={cta.name || ''}
            onChange={e => onChangeCta({ ...cta, name: e.target.value })}
            className="custom-form-text"
            placeholder="e.g My awesome CTA"
            required="required"
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="custom-form-label">Body</label>
        <FormHint text="Text above the form" />
        <div>
          <textarea
            name="call_to_action[payload][body]"
            value={cta.payload.body || ''}
            onChange={e => onChangeCta({ ...cta, payload: { ...cta.payload, body: e.target.value } })}
            className="custom-form-text"
            placeholder="e.g This is my new Call-to-Action"
            required="required"
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="custom-form-label">Input placeholder</label>
        <FormHint text="Text the user will click to get to your site" />
        <div>
          <input
            type="text"
            name="call_to_action[payload][input_placeholder]"
            value={cta.payload.input_placeholder || ''}
            onChange={e => onChangeCta({ ...cta, payload: { ...cta.payload, input_placeholder: e.target.value } })}
            className="custom-form-text"
            placeholder="e.g. Click to learn more"
            required="required"
          />
        </div>
      </div>
      <div className="mb-4">
        <div>
          <label className="custom-form-label">Submit Button Anchor Text</label>
          <input
            type="text"
            name="call_to_action[payload][btn_text]"
            value={cta.payload.btn_text || ''}
            onChange={e => onChangeCta({ ...cta, payload: { ...cta.payload, btn_text: e.target.value } })}
            className="custom-form-text"
            placeholder="e.g My awesome CTA"
            required="required"
          />
        </div>
      </div>
      <div className="mb-4 text-center font-medium">
        <a onClick={() => setShowAdvanced(!showAdvanced)} className="btn btn-sm btn-default text-xs">
          {showAdvanced ? 'Close advanced options' : 'Open advanced options'}
        </a>
      </div>
      <CallToActionAdvancedOptions cta={cta} user={user} onChange={onChangeCta} show={showAdvanced} />
    </React.Fragment>
  )
}

CallToActionForm.propTypes = {
  cta: PropTypes.object,
  onChangeCta: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  user: PropTypes.object
}

export default CallToActionForm
