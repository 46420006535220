import React from 'react'
import PropTypes from 'prop-types'

import ButtonPreview from './preview/ButtonPreview'
import WhatsAppPreview from './preview/WhatsAppPreview'
import FormPreview from './preview/FormPreview'
import PhonePreview from './preview/PhonePreview'

const CallToActionPreviewComponent = ({ cta }) => {
  switch (cta.cta_type) {
    case 'whatsapp':
      return <WhatsAppPreview cta={cta} />
    case 'phone':
      return <PhonePreview cta={cta} />
    case 'form':
      return <FormPreview cta={cta} />
    case 'button':
    default:
      return <ButtonPreview cta={cta} />
  }
}

CallToActionPreviewComponent.propTypes = {
  cta: PropTypes.object.isRequired
}

const CallToActionPreview = ({ cta }) => {
  return (
    <div
      className="md:block md:w-5/6 bg-white rounded shadow-md relative mb-20 pb-10"
      style={{ height: 'fit-content' }}
    >
      <CallToActionPreviewComponent cta={cta} />
    </div>
  )
}

CallToActionPreview.propTypes = {
  cta: PropTypes.object.isRequired
}

export default CallToActionPreview
