import React from 'react'
import PropTypes from 'prop-types'

import SelectField from '../common/SelectField'
import CheckBoxField from '../common/CheckBoxField'
import FormHint from '../common/FormHint'

const POSITION_OPTIONS = [
  { label: 'Bottom Left', value: 'bottom-left' },
  { label: 'Bottom Right', value: 'bottom-right' },
  { label: 'Top Left', value: 'top-left' },
  { label: 'Top Right', value: 'top-right' }
]

const TEXT_DIRECTION_OPTIONS = [
  { label: 'Left-To-Right', value: 'ltr' },
  { label: 'Right-To-Left', value: 'rtl' }
]

const CallToActionAdvancedOptions = ({ cta, user, onChange, show }) => {
  return (
    <div
      className={`transform transition ease-out duration-300 ${
        show ? 'opacity-100 scale-100 h-full' : 'opacity-0 scale-0 h-0'
      }`}
    >
      <div className="flex flex-row justify-between">
        <div className="mb-4 text-center">
          <label className="custom-form-label">Text color</label>
          <div>
            <input
              type="color"
              name="call_to_action[payload][text_color]"
              value={cta.payload.text_color}
              onChange={e => onChange({ ...cta, payload: { ...cta.payload, text_color: e.target.value } })}
              className="color-input"
            />
          </div>
        </div>
        <div className="mb-4 text-center">
          <label className="custom-form-label">Text background Color</label>
          <div>
            <input
              type="color"
              name="call_to_action[payload][bg_color]"
              value={cta.payload.bg_color}
              onChange={e => onChange({ ...cta, payload: { ...cta.payload, bg_color: e.target.value } })}
              className="color-input"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="mb-4 text-center">
          <label className="custom-form-label">Button text color</label>
          <div>
            <input
              type="color"
              name="call_to_action[payload][btn_text_color]"
              value={cta.payload.btn_text_color}
              onChange={e => onChange({ ...cta, payload: { ...cta.payload, btn_text_color: e.target.value } })}
              className="color-input"
            />
          </div>
        </div>
        <div className="mb-4 text-center">
          <label className="custom-form-label">Button background Color</label>
          <div>
            <input
              type="color"
              name="call_to_action[payload][btn_bg_color]"
              value={cta.payload.btn_bg_color}
              onChange={e => onChange({ ...cta, payload: { ...cta.payload, btn_bg_color: e.target.value } })}
              className="color-input"
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <SelectField
          label="Position"
          name="call_to_action[payload][position]"
          value={cta.payload.position}
          onChange={e => onChange({ ...cta, payload: { ...cta.payload, position: e.target.value } })}
          options={POSITION_OPTIONS}
        />
      </div>
      <div className="mb-4">
        <SelectField
          label="Text Direction"
          name="call_to_action[payload][direction]"
          value={cta.payload.direction}
          onChange={e => onChange({ ...cta, payload: { ...cta.payload, direction: e.target.value } })}
          options={TEXT_DIRECTION_OPTIONS}
        />
      </div>
      <div className="mb-4">
        <CheckBoxField
          label="Open CTA in new tab"
          name="call_to_action[payload][new_tab]"
          checked={cta.payload.new_tab}
          onChange={() => onChange({ ...cta, payload: { ...cta.payload, new_tab: !cta.payload.new_tab } })}
        />
      </div>
      <div className="mb-4">
        <CheckBoxField
          label="Remove Linkush branding"
          name="call_to_action[payload][remove_branding]"
          checked={cta.payload.remove_branding}
          disabledHint="This feature is not included in your current plan. Please upgrade your plan to use this feature"
          disabled={user.branding_removable}
          onChange={() =>
            onChange({ ...cta, payload: { ...cta.payload, remove_branding: !cta.payload.remove_branding } })
          }
        />
      </div>
      <div className="mb-4">
        <CheckBoxField
          label="Redirect to original URL"
          name="call_to_action[payload][redirect_to_original]"
          tooltip="Automatically redirect to original URL"
          checked={cta.payload.redirect_to_original}
          onChange={() =>
            onChange({ ...cta, payload: { ...cta.payload, redirect_to_original: !cta.payload.redirect_to_original } })
          }
        />
      </div>
      <div className={`mb-4 ${cta.payload.redirect_to_original ? 'block' : 'hidden'}`}>
        <label className="custom-form-label">Redirect delay (milliseconds)</label>
        <FormHint text="Keep empty if you want to redirect immediately. Otherwise set the delay in milliseconds" />
        <div>
          <input
            type="number"
            name="call_to_action[payload][redirect_delay]"
            min="0"
            value={cta.payload.redirect_delay}
            onChange={e => onChange({ ...cta, payload: { ...cta.payload, redirect_delay: e.target.value } })}
            className="custom-form-text"
            placeholder="e.g. 2000"
          />
        </div>
      </div>
    </div>
  )
}

CallToActionAdvancedOptions.propTypes = {
  cta: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  show: PropTypes.bool
}

CallToActionAdvancedOptions.defaultProps = {
  show: false
}

export default CallToActionAdvancedOptions
